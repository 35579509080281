import styled from 'styled-components'
import { STYLE } from '../../style'

const FieldValidationMessage = styled.div`
  color: red;
  font-size: ${STYLE.FONT_SIZE_SM};
  text-align: right;
`

export default FieldValidationMessage
