import styled from 'styled-components'
import Button, { ButtonHtmlType } from './Button'
import { STYLE } from '../../style'

const SubmitButton = styled(Button).attrs({
  children: 'Submit',
  htmlType: ButtonHtmlType.SUBMIT,
})`
  margin-top: ${STYLE.GUTTER};
`

export default SubmitButton
