/* New Style - Mobile first */
export const BREAKPOINT = {
  TABLET: '768px',
  DESKTOP: '1200px',
}

export const SCREEN_SIZE = {
  TABLET: `@media only screen and (min-width: ${BREAKPOINT.TABLET})`,
  DESKTOP: `@media only screen and (min-width: ${BREAKPOINT.DESKTOP})`,
}

/* For UseBreakpoint Hook */
export enum DEVICES {
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP',
}
