import * as React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import RadioGroup from '../../components/Radio/RadioGroup'
import TextField from '../../components/TextField/TextField'
import { FieldHintTextPlacement } from '../../components/types'
import TextArea from '../../components/TextArea/TextArea'
import HeaderText from '../../components/Text/HeaderText'
import PaymentAmountInfo from '../PaymentAmount/PaymentAmountInfo'
// @ts-ignore
import MedicareCardImageSrc from '../../images/medicare-card.png'
import {
  YES_NO_RADIO_ITEMS,
  YES_RADIO_ITEM,
} from '../../components/Radio/constants'
import { IPrescriptionFormFields } from '../../types'
import PrescriptionSendToRadioGroup from '../Radio/PrescriptionSendToRadioGroup/PrescriptionSendToRadioGroup'
import TwoColumnFormView from '../../components/Form/TwoColumnFormView'
import { STYLE } from '../../style'
import FormContext from '../../context/FormContext'
import SubmitButton from '../../components/Button/SubmitButton'
import PrescriptionFormRules from './PrescriptionFormRules'
import { FormHelper } from '../../helpers/FormHelper'
import { ICAClinicsState } from '../../states/types'

const StyledPrescriptionForm = styled.form``

const MedicareCardImage = styled.img.attrs({
  src: MedicareCardImageSrc,
})`
  width: 80%;
  margin: 0 auto 1.4rem;
  display: block;
`

interface IPrescriptionFormProps {
  fields: IPrescriptionFormFields
  setFields: (fields: IPrescriptionFormFields) => void
  isFormDirty: boolean
  setIsFormDirty: (isFormDirty: boolean) => void
  onSubmit: (fields: IPrescriptionFormFields) => void
}

const PrescriptionForm: React.FunctionComponent<IPrescriptionFormProps> = ({
  fields,
  setFields,
  isFormDirty,
  setIsFormDirty,
  onSubmit,
}) => {
  const isProcessing: boolean = useSelector(
    ({ prescriptionForm }: ICAClinicsState) => prescriptionForm.isProcessing,
  )

  const updateFields = (newFields: any) => {
    setFields({ ...fields, ...newFields })
  }
  return (
    <FormContext.Provider value={{ isFormDirty }}>
      <StyledPrescriptionForm
        name="prescription-form"
        onSubmit={(ev) => {
          ev.preventDefault()
          setIsFormDirty(true)
          const { isValid, validation } =
            FormHelper.validatePrescriptionForm(fields)
          console.log('validation', isValid, validation)
          if (isValid) {
            onSubmit(fields)
          }
        }}
      >
        <RadioGroup
          disabled={isProcessing}
          items={YES_NO_RADIO_ITEMS}
          labelText="Are you an existing patient of CA Clinics"
          hintText="Only existing patients can request an additional script"
          onChange={(ev, value: string) => {
            updateFields({ existingCustomer: value })
          }}
          value={fields.existingCustomer}
        />
        {fields.existingCustomer === YES_RADIO_ITEM.value && (
          <>
            <TextField
              disabled={isProcessing}
              labelText="First name"
              rules={PrescriptionFormRules.firstName}
              value={fields.firstName}
              onChange={(ev, value: string) =>
                updateFields({ firstName: value })
              }
            />
            <TextField
              disabled={isProcessing}
              labelText="Last name"
              rules={PrescriptionFormRules.lastName}
              value={fields.lastName}
              onChange={(ev, value: string) =>
                updateFields({ lastName: value })
              }
            />
            <TextField
              disabled={isProcessing}
              labelText="Email"
              rules={PrescriptionFormRules.email}
              value={fields.email}
              onChange={(ev, value: string) => updateFields({ email: value })}
            />
            <TextField
              disabled={isProcessing}
              labelText="Mobile"
              placeholder="0401123456"
              maxLength={10}
              rules={PrescriptionFormRules.mobile}
              value={fields.mobile}
              onChange={(ev, value: string) => updateFields({ mobile: value })}
            />
            <TextField
              disabled={isProcessing}
              labelText="Medicare card no."
              placeholder="1234567891"
              maxLength={10}
              rules={PrescriptionFormRules.medicareCardNo}
              value={fields.medicareCardNo}
              onChange={(ev, value: string) =>
                updateFields({ medicareCardNo: value })
              }
            />
            <TwoColumnFormView>
              <TextField
                disabled={isProcessing}
                labelText="Individual reference no."
                rules={PrescriptionFormRules.medicareCardIRN}
                placeholder="1"
                maxLength={1}
                value={fields.medicareCardIRN}
                onChange={(ev, value: string) =>
                  updateFields({ medicareCardIRN: value })
                }
              />
              <TextField
                disabled={isProcessing}
                labelText="Valid to"
                maxLength={7}
                rules={PrescriptionFormRules.medicareCardValidTo}
                placeholder="MM/YYYY"
                value={fields.medicareCardValidTo}
                onChange={(ev, value: string) =>
                  updateFields({ medicareCardValidTo: value })
                }
              />
            </TwoColumnFormView>
            <RadioGroup
              disabled={isProcessing}
              items={[
                {
                  text: 'Male',
                  value: 'MALE',
                },
                { text: 'Female', value: 'FEMALE' },
              ]}
              labelText="Gender"
              rules={PrescriptionFormRules.gender}
              value={fields.gender}
              onChange={(ev, value) => updateFields({ gender: value })}
              hintText="required to validate Medicare card"
              hintTextPlacement={FieldHintTextPlacement.TOP}
            />
            <MedicareCardImage />
            <RadioGroup
              items={YES_NO_RADIO_ITEMS}
              disabled={isProcessing}
              rules={PrescriptionFormRules.knowProductForSubscription}
              labelText="Do you know what product you need a script for?"
              hintText="You can only request a script for a medicine that you have already been approved for"
              onChange={(ev, value: string) =>
                updateFields({ knowProductForSubscription: value })
              }
              value={fields.knowProductForSubscription}
            />
            {fields.knowProductForSubscription === YES_RADIO_ITEM.value && (
              <TextField
                paddingLeft={1}
                disabled={isProcessing}
                fontSize={STYLE.FONT_SIZE_SM}
                labelText="What is the name of the product you need a script for?"
                value={fields.productForPrescription}
                onChange={(ev, value: string) =>
                  updateFields({ productForPrescription: value })
                }
              />
            )}
            <RadioGroup
              items={YES_NO_RADIO_ITEMS}
              disabled={isProcessing}
              labelText="Would you like a script for a cheaper equivalent product if available?"
              rules={PrescriptionFormRules.lookForCheaperProduct}
              value={fields.lookForCheaperProduct}
              onChange={(ev, value: string) =>
                updateFields({
                  lookForCheaperProduct: value,
                })
              }
            />
            <PrescriptionSendToRadioGroup
              values={fields}
              disabled={isProcessing}
              onChange={(ev, values) => updateFields(values)}
            />
            <TextArea
              labelText="Please provide any other comments or questions"
              value={fields.comments}
              disabled={isProcessing}
              onChange={(ev, value: string) =>
                updateFields({ comments: value })
              }
            />

            <HeaderText>Payment Information</HeaderText>
            <PaymentAmountInfo fields={fields} />
            <TextField
              labelText="Name on card"
              disabled={isProcessing}
              rules={PrescriptionFormRules.creditCardName}
              value={fields.creditCardName}
              onChange={(ev, value: string) =>
                updateFields({ creditCardName: value })
              }
            />
            <TextField
              labelText="Card number"
              disabled={isProcessing}
              rules={PrescriptionFormRules.creditCardNo}
              placeholder="1234123412341234"
              maxLength={16}
              value={fields.creditCardNo}
              onChange={(ev, value: string) =>
                updateFields({ creditCardNo: value })
              }
            />
            <TwoColumnFormView>
              <TextField
                labelText="Expiry date"
                placeholder="MM/YY"
                disabled={isProcessing}
                maxLength={5}
                rules={PrescriptionFormRules.creditCardExpiryDate}
                value={fields.creditCardExpiryDate}
                onChange={(ev, value: string) =>
                  updateFields({ creditCardExpiryDate: value })
                }
              />
              <TextField
                labelText="Security / CVC"
                disabled={isProcessing}
                maxLength={3}
                placeholder="123"
                rules={PrescriptionFormRules.creditCardCVC}
                value={fields.creditCardCVC}
                onChange={(ev, value: string) =>
                  updateFields({ creditCardCVC: value })
                }
              />
            </TwoColumnFormView>
            <SubmitButton loading={isProcessing} />
          </>
        )}
      </StyledPrescriptionForm>
    </FormContext.Provider>
  )
}

export default PrescriptionForm
