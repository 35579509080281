import * as React from 'react'
import styled from 'styled-components'
import TextField from '../../../components/TextField/TextField'
import SelectField from '../../../components/SelectField/SelectField'
import { IPrescriptionFormFields, States } from '../../../types'
import { STYLE } from '../../../style'
import TwoColumnFormView from '../../../components/Form/TwoColumnFormView'
import PrescriptionFormRules from '../../PrescriptionForm/PrescriptionFormRules'

interface IPrescriptionSendToFormProps {
  values: IPrescriptionFormFields
  onChange: (ev: React.FormEvent<HTMLInputElement>, values: object) => void
}

const StyledSendToMyAddressForm = styled.div`
  margin: ${STYLE.GUTTER} 0;
`

const SendToMyAddressForm: React.FunctionComponent<IPrescriptionSendToFormProps> =
  ({ values, onChange }) => (
    <StyledSendToMyAddressForm>
      <TextField
        labelText="Street address"
        rules={PrescriptionFormRules.clientStreetAddress}
        value={values.clientStreetAddress}
        onChange={(ev, value: string) =>
          onChange(ev, { clientStreetAddress: value })
        }
      />
      <TextField
        labelText="Suburb"
        rules={PrescriptionFormRules.clientSuburb}
        value={values.clientSuburb}
        onChange={(ev, value: string) => onChange(ev, { clientSuburb: value })}
      />
      <TwoColumnFormView>
        <TextField
          labelText="Postcode"
          rules={PrescriptionFormRules.clientPostcode}
          value={values.clientPostcode}
          onChange={(ev, value: string) =>
            onChange(ev, { clientPostcode: value })
          }
        />
        <SelectField
          items={States}
          labelText="State"
          rules={PrescriptionFormRules.clientState}
          value={values.clientState}
          onChange={(ev, value: string) => onChange(ev, { clientState: value })}
        />
      </TwoColumnFormView>
    </StyledSendToMyAddressForm>
  )

export default SendToMyAddressForm
