import * as React from 'react'
import styled from 'styled-components'
import { COLOR, STYLE } from '../../style'

interface IHintTextProps {
  children: React.ReactNode
}

const StyledHintText = styled.div`
  color: ${COLOR.HINT_TEXT_COLOR};
  font-size: ${STYLE.FONT_SIZE_XS};
`

const HintText: React.FunctionComponent<IHintTextProps> = ({ children }) => (
  <StyledHintText>{children}</StyledHintText>
)

export default HintText
