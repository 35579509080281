import * as React from 'react'
import styled from 'styled-components'

interface ITextProps {
  children: React.ReactNode
  required?: boolean
}

const RequiredText = styled.span`
  color: red;
`

const StyledLabelText = styled.div`
  margin-bottom: 0.25rem;
`

const LabelText: React.FunctionComponent<ITextProps> = ({
  children,
  required,
}) => (
  <StyledLabelText>
    {children}
    {required && <RequiredText>&nbsp;*</RequiredText>}
  </StyledLabelText>
)

export default LabelText
