import * as React from 'react'
import { useState } from 'react'
import { Input } from 'antd'
import styled from 'styled-components'
import { IFieldProps } from '../types'
import { STYLE } from '../../style'
import { FormHelper } from '../../helpers/FormHelper'
import { FieldValidationRule } from '../../types'
import FieldHeader from '../Field/FieldHeader'
import FieldRules from '../Field/FieldRules'

interface ITextFieldProps extends IFieldProps {
  paddingLeft?: number
  fontSize?: string
  maxLength?: number
}

const StyledTextField = styled.div`
  ${STYLE.FIELD_STYLE};
  ${({ paddingLeft, fontSize }: { paddingLeft: number; fontSize: string }) =>
    `
    ${paddingLeft && `padding-left: calc(${paddingLeft} * 2rem);`}
    ${fontSize && `font-size: ${fontSize};`}
  `}
`

const TextField: React.FunctionComponent<ITextFieldProps> = ({
  paddingLeft,
  placeholder,
  fontSize,
  labelText,
  disabled,
  maxLength,
  rules = [],
  onChange,
  value,
}) => {
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const invalidRule: FieldValidationRule = FormHelper.validate(
    rules.filter((a) => a),
    value,
  )
  return (
    <StyledTextField paddingLeft={paddingLeft} fontSize={fontSize}>
      <FieldHeader
        isDirty={isDirty}
        required={rules.includes(FieldRules.required)}
        validationMessage={invalidRule?.message}
        labelText={labelText}
      />
      <Input
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        onChange={(ev) => {
          setIsDirty(true)
          onChange(ev, ev.target.value, invalidRule)
        }}
        value={value}
      />
    </StyledTextField>
  )
}

export default TextField
