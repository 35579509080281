import { FieldValidationRule } from '../../types'

const FieldRules: { [key: string]: FieldValidationRule } = {
  required: {
    validate: (v: string) => v?.trim().length > 0,
    message: '* required',
  },
  email: {
    validate: (v: string) =>
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        v,
      ),
    message: '* invalid email',
  },
  mobile: {
    validate: (v: string) => /^04[0-9]{8}$/.test(v),
    message: '* 10 digits',
  },
  medicareCardNo: {
    validate: (v: string): boolean => {
      if (!/^[0-9]{10}$/.test(v)) {
        return false
      }
      const matches = v.match(/^([2-6]\d{7})(\d)/)
      if (!matches || matches.length < 3) {
        return false
      }
      const [, base, checkDigit] = matches
      let sum: number = 0
      const weights: Array<number> = [1, 3, 7, 9, 1, 3, 7, 9]
      weights.forEach((weight: number, position: number) => {
        sum += Number(base[position]) * weights[position]
      })
      return Math.round(sum % 10) === Number(checkDigit)
    },
    message: '* invalid card number',
  },
  medicareCardIRN: {
    validate: (v: string): boolean => /^[0-9]$/.test(v),
    message: '* must be a number',
  },
  monthYearLong: {
    validate: (v: string): boolean =>
      /(^0[1-9]\/20[0-9]{2}$|^10\/20[0-9]{2}$|^11\/20[0-9]{2}$|^12\/20[0-9]{2}$)/.test(
        v,
      ),
    message: '* MM/YYYY format',
  },
  monthYearShort: {
    validate: (v: string): boolean =>
      /(^0[1-9]\/[0-9]{2}$|^10\/[0-9]{2}$|^11\/[0-9]{2}$|^12\/[0-9]{2}$)/.test(
        v,
      ),
    message: '* MM/YY format',
  },
  creditCardCVC: {
    validate: (v: string): boolean => /^[0-9]{3}$/.test(v),
    message: '* 3 digits',
  },
  creditCardNo: {
    validate: (v: string): boolean => /^[0-9]{16}$/.test(v),
    message: '* 16 digits',
  },
}

export default FieldRules
