import * as React from 'react'
import styled from 'styled-components'
import TextField from '../../../components/TextField/TextField'
import SelectField from '../../../components/SelectField/SelectField'
import { IPrescriptionFormFields, States } from '../../../types'
import { STYLE } from '../../../style'
import TwoColumnFormView from '../../../components/Form/TwoColumnFormView'
import PrescriptionFormRules from '../../PrescriptionForm/PrescriptionFormRules'

interface IPrescriptionSendToFormProps {
  values: IPrescriptionFormFields
  onChange: (ev: React.FormEvent<HTMLInputElement>, values: object) => void
}

const StyledSendToMyPharmacyForm = styled.div`
  margin: ${STYLE.GUTTER} 0;
`

const SendToMyPharmacyForm: React.FunctionComponent<IPrescriptionSendToFormProps> =
  ({ values, onChange }) => (
    <StyledSendToMyPharmacyForm>
      <TextField
        labelText="Pharmacy email"
        rules={PrescriptionFormRules.pharmacyEmail}
        value={values.pharmacyEmail}
        onChange={(ev, value: string) => onChange(ev, { pharmacyEmail: value })}
      />
      <TextField
        labelText="Pharmacy name"
        rules={PrescriptionFormRules.pharmacyName}
        value={values.pharmacyName}
        onChange={(ev, value: string) => onChange(ev, { pharmacyName: value })}
      />
      <TextField
        labelText="Street address"
        rules={PrescriptionFormRules.pharmacyStreetAddress}
        value={values.pharmacyStreetAddress}
        onChange={(ev, value: string) =>
          onChange(ev, { pharmacyStreetAddress: value })
        }
      />
      <TextField
        labelText="Suburb"
        rules={PrescriptionFormRules.pharmacySuburb}
        value={values.pharmacySuburb}
        onChange={(ev, value: string) =>
          onChange(ev, { pharmacySuburb: value })
        }
      />
      <TwoColumnFormView>
        <TextField
          labelText="Postcode"
          rules={PrescriptionFormRules.pharmacyPostcode}
          value={values.pharmacyPostcode}
          onChange={(ev, value: string) =>
            onChange(ev, { pharmacyPostcode: value })
          }
        />
        <SelectField
          items={States}
          labelText="State"
          rules={PrescriptionFormRules.pharmacyState}
          value={values.pharmacyState}
          onChange={(ev, value: string) =>
            onChange(ev, { pharmacyState: value })
          }
        />
      </TwoColumnFormView>
    </StyledSendToMyPharmacyForm>
  )

export default SendToMyPharmacyForm
