import { FieldHintTextPlacement, IFieldProps } from '../types'

export interface IRadioGroupItem {
  text: string
  value: string
  children?: React.ReactNode
}

export enum RadioGroupDirection {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export interface IRadioGroupProps extends IFieldProps {
  items: Array<IRadioGroupItem>
  hintText?: string
  hintTextPlacement?: FieldHintTextPlacement
  direction?: RadioGroupDirection
}
