import { IPrescriptionFormFields, PrescriptionSendTo } from '../../types'
import { YES_RADIO_ITEM } from '../../components/Radio/constants'

const PrescriptionFormInitialFormValue: IPrescriptionFormFields = {
  clientPostcode: '',
  clientState: undefined,
  clientStreetAddress: '',
  clientSuburb: '',
  pharmacyEmail: '',
  pharmacyName: '',
  pharmacyPostcode: '',
  pharmacyState: undefined,
  pharmacyStreetAddress: '',
  pharmacySuburb: '',
  existingCustomer: YES_RADIO_ITEM.value,
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  gender: null,
  medicareCardNo: '',
  medicareCardIRN: '',
  medicareCardValidTo: '',
  knowProductForSubscription: undefined,
  productForPrescription: '',
  sendPrescriptionTo: PrescriptionSendTo.CACLINICS,
  comments: '',
  creditCardNo: '',
  creditCardName: '',
  creditCardExpiryDate: '',
  creditCardCVC: '',
  lookForCheaperProduct: undefined,
}

export default PrescriptionFormInitialFormValue
