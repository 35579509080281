import { FieldValidationRule } from '../types'

export enum FieldHintTextPlacement {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export interface IFieldProps {
  placeholder?: string
  labelText?: string
  rules?: Array<FieldValidationRule>
  onChange?: (ev, value: string, invalidRule?: FieldValidationRule) => void
  value: string
  required?: boolean
  disabled: boolean
}
