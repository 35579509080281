import * as React from 'react'
import { RadioGroupDirection } from '../../../components/Radio/types'
import RadioGroup from '../../../components/Radio/RadioGroup'
import { IPrescriptionFormFields, PrescriptionSendTo } from '../../../types'
import SendToMyPharmacyForm from './SendToMyPharmacyForm'
import SendToMyAddressForm from './SendToMyAddressForm'

interface IPrescriptionSendToRadioGroupProps {
  onChange: (ev: React.FormEvent<HTMLInputElement>, values: object) => void
  values: IPrescriptionFormFields
  disabled: boolean
}

const PrescriptionSendToRadioGroup: React.FunctionComponent<IPrescriptionSendToRadioGroupProps> =
  ({ values, onChange, disabled }) => (
    <RadioGroup
      disabled={disabled}
      items={[
        {
          text: 'CA Clinics preferred pharmacy - no additional fees',
          value: PrescriptionSendTo.CACLINICS,
        },
        {
          text: 'My pharmacy’s address - $27.50 in additional shipping and handling fees',
          value: PrescriptionSendTo.MY_PHARMACY,
          children: (
            <SendToMyPharmacyForm values={values} onChange={onChange} />
          ),
        },
        {
          text: 'My address - $27.50 in additional shipping and handling fees',
          value: PrescriptionSendTo.MY_ADDRESS,
          children: <SendToMyAddressForm values={values} onChange={onChange} />,
        },
      ]}
      direction={RadioGroupDirection.VERTICAL}
      labelText="Please send the prescription to:"
      value={values.sendPrescriptionTo}
      onChange={(ev, value: string) =>
        onChange(ev, { sendPrescriptionTo: value })
      }
    />
  )

export default PrescriptionSendToRadioGroup
