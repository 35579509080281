import * as React from 'react'
import { useState } from 'react'
import qs from 'qs'
import { useDispatch } from 'react-redux'
import { PageProps } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout/Layout'
import PrescriptionForm from '../containers/PrescriptionForm/PrescriptionForm'
import { IPrescriptionFormFields, ProjectId } from '../types'
import {
  createPaymentToken,
  processPrescriptionPayment,
} from '../states/prescriptionForm/actions'
import PrescriptionFormInitialFormValue from '../containers/PrescriptionForm/PrescriptionFormInitialValue'

const StyledHomePage = styled(Layout)``

const Secure3dFrameWrapper = styled.div`
  ${({ isShow }: { isShow: boolean }) =>
    isShow
      ? `
    display: block;`
      : `
    display: none;
  `}
`

const Secure3dFrame = styled.iframe`
  background-color: rgba(245, 245, 245, 0.5);
  margin-top: 1.6rem;
  width: 100%;
  height: 450px;
  max-height: 95%;

  .text {
    color: black;
  }
`

// markup
const HomePage: React.FunctionComponent<PageProps> = ({ location }) => {
  const dispatch = useDispatch()
  const [isShowSecure3dForm, setShowSecure3dForm] = useState<boolean>(false)
  const [fields, setFields] = useState<IPrescriptionFormFields>(
    PrescriptionFormInitialFormValue,
  )
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false)
  const { projectId }: { projectId: ProjectId } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  return (
    <StyledHomePage>
      {!isShowSecure3dForm && (
        <PrescriptionForm
          fields={fields}
          setFields={setFields}
          isFormDirty={isFormDirty}
          setIsFormDirty={setIsFormDirty}
          onSubmit={(fields: IPrescriptionFormFields) => {
            dispatch(
              createPaymentToken(projectId, fields, {
                onTokenReceived: () => {
                  setShowSecure3dForm(true)
                },
                onComplete: ({ prescriptionFormFields, projectId, token }) => {
                  dispatch(
                    processPrescriptionPayment(
                      projectId,
                      prescriptionFormFields,
                      token,
                      () => {
                        setShowSecure3dForm(false)
                      },
                    ),
                  )
                },
              }),
            )
          }}
        />
      )}
      <Secure3dFrameWrapper isShow={isShowSecure3dForm}>
        <Secure3dFrame name="secure3d-frame" id="secure3d-frame" />
      </Secure3dFrameWrapper>
    </StyledHomePage>
  )
}
export default HomePage
