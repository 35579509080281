import * as React from 'react'
import styled from 'styled-components'
import LabelText from '../Text/LabelText'
import FieldValidationMessage from './FieldValidationMessage'
import FormContext from '../../context/FormContext'
import { useContext } from 'react'
import HintText from '../Text/HintText'

interface IFieldHeaderProps {
  required: boolean
  labelText?: string
  isDirty: boolean
  validationMessage?: string
  hintText?: string
}

const StyledFieldHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const MessageContainer = styled.div``

const FieldHeader: React.FunctionComponent<IFieldHeaderProps> = ({
  required,
  validationMessage,
  labelText,
  isDirty,
  hintText,
}) => {
  const { isFormDirty } = useContext(FormContext)
  return (
    <StyledFieldHeader>
      {labelText && <LabelText required={required}>{labelText}</LabelText>}
      <MessageContainer>
        {(isDirty || isFormDirty) && validationMessage && (
          <FieldValidationMessage>{validationMessage}</FieldValidationMessage>
        )}
        {hintText && <HintText>{hintText}</HintText>}
      </MessageContainer>
    </StyledFieldHeader>
  )
}

export default FieldHeader
