import * as React from 'react'
import styled from 'styled-components'
import { IFieldProps } from '../types'
import { COLOR, STYLE } from '../../style'
import { FieldValidationRule } from '../../types'
import { FormHelper } from '../../helpers/FormHelper'
import FieldRules from '../Field/FieldRules'
import { useState } from 'react'
import FieldHeader from '../Field/FieldHeader'

interface ISelectFieldItemProps {
  text: string
  value: string
}

interface ISelectFieldProps extends IFieldProps {
  items: Array<ISelectFieldItemProps>
  rules?: Array<FieldValidationRule>
}

const SelectFieldContainer = styled.div``
const StyledSelectField = styled.select`
  width: 100%;
  font-size: ${STYLE.FONT_SIZE_MD};
  padding: 5px 11px;
  border-color: ${COLOR.FIELD_BORDER_COLOR};
`
const SelectFieldItem = styled.option``

const SelectField: React.FunctionComponent<ISelectFieldProps> = ({
  items,
  labelText,
  required,
  onChange,
  rules,
  value,
}) => {
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const invalidRule: FieldValidationRule = FormHelper.validate(
    [required && FieldRules.required].concat(rules).filter((a) => a),
    value,
  )

  return (
    <SelectFieldContainer>
      <FieldHeader
        isDirty={isDirty}
        required={required}
        validationMessage={invalidRule?.message}
        labelText={labelText}
      />
      <StyledSelectField
        value={value}
        onChange={(ev) => {
          setIsDirty(true)
          onChange(ev, ev.target.value, invalidRule)
        }}
      >
        {items.map((i: ISelectFieldItemProps) => (
          <SelectFieldItem key={i.value} value={i.value}>
            {i.text}
          </SelectFieldItem>
        ))}
      </StyledSelectField>
    </SelectFieldContainer>
  )
}

export default SelectField
