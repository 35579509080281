import styled from 'styled-components'
import { STYLE } from '../../style'

const HeaderText = styled.div`
  font-weight: ${STYLE.FONT_WEIGHT_SEMI_BOLD};
  font-size: ${STYLE.FONT_SIZE_XL};
  margin: 2.8rem 0 0.5rem;
`

export default HeaderText
