import {
  FieldValidationRule,
  IPrescriptionFormFields,
  PrescriptionSendTo,
} from '../types'
import { FormValidationRules } from '../components/Form/types'
import PrescriptionFormRules from '../containers/PrescriptionForm/PrescriptionFormRules'

const FormHelper = {
  validate: (
    rules: Array<FieldValidationRule>,
    value: string,
  ): FieldValidationRule => {
    for (const rule of rules) {
      if (!rule.validate(value)) {
        return rule
      }
    }
  },

  validateForm: (
    fields: IPrescriptionFormFields,
    formRules: FormValidationRules,
  ): {
    isValid: boolean
    validation: { [key: string]: FieldValidationRule }
  } => {
    let retObj: { [key: string]: FieldValidationRule } = {}
    for (const [key, rules] of Object.entries(formRules)) {
      const validationResult = FormHelper.validate(rules, fields[key])
      if (validationResult) {
        retObj[key] = validationResult
      }
    }
    return { isValid: Object.keys(retObj).length === 0, validation: retObj }
  },

  getPrescriptionFormRules: (
    sendPrescriptionTo: PrescriptionSendTo,
  ): FormValidationRules => {
    const {
      clientStreetAddress,
      clientSuburb,
      clientPostcode,
      clientState,
      pharmacyName,
      pharmacyEmail,
      pharmacyStreetAddress,
      pharmacySuburb,
      pharmacyPostcode,
      pharmacyState,
      ...general
    } = PrescriptionFormRules
    if (sendPrescriptionTo === PrescriptionSendTo.CACLINICS) {
      return general
    } else if (sendPrescriptionTo === PrescriptionSendTo.MY_PHARMACY) {
      return {
        ...general,
        pharmacyName,
        pharmacyEmail,
        pharmacyStreetAddress,
        pharmacySuburb,
        pharmacyPostcode,
        pharmacyState,
      }
    } else if (sendPrescriptionTo === PrescriptionSendTo.MY_ADDRESS) {
      return {
        ...general,
        clientStreetAddress,
        clientSuburb,
        clientPostcode,
        clientState,
      }
    }
    return
  },

  validatePrescriptionForm: (fields: IPrescriptionFormFields) => {
    const prescriptionFormRules: FormValidationRules =
      FormHelper.getPrescriptionFormRules(fields.sendPrescriptionTo)
    return FormHelper.validateForm(fields, prescriptionFormRules)
  },
}

export { FormHelper }
