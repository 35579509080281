import { FormValidationRules } from '../../components/Form/types'
import FieldRules from '../../components/Field/FieldRules'

const PrescriptionFormRules: FormValidationRules = {
  firstName: [FieldRules.required],
  lastName: [FieldRules.required],
  email: [FieldRules.required, FieldRules.email],
  mobile: [FieldRules.required, FieldRules.mobile],
  gender: [FieldRules.required],
  knowProductForSubscription: [FieldRules.required],
  lookForCheaperProduct: [FieldRules.required],
  pharmacyName: [FieldRules.required],
  pharmacyEmail: [FieldRules.required, FieldRules.email],
  pharmacyStreetAddress: [FieldRules.required],
  pharmacySuburb: [FieldRules.required],
  pharmacyPostcode: [FieldRules.required],
  pharmacyState: [FieldRules.required],
  clientStreetAddress: [FieldRules.required],
  clientSuburb: [FieldRules.required],
  clientPostcode: [FieldRules.required],
  clientState: [FieldRules.required],
  medicareCardNo: [FieldRules.required, FieldRules.medicareCardNo],
  medicareCardIRN: [FieldRules.required, FieldRules.medicareCardIRN],
  medicareCardValidTo: [FieldRules.required, FieldRules.monthYearLong],
  creditCardName: [FieldRules.required],
  creditCardNo: [FieldRules.required, FieldRules.creditCardNo],
  creditCardExpiryDate: [FieldRules.required, FieldRules.monthYearShort],
  creditCardCVC: [FieldRules.required, FieldRules.creditCardCVC],
}

export default PrescriptionFormRules
