import * as React from 'react'
import { Input } from 'antd'
import styled from 'styled-components'
import LabelText from '../Text/LabelText'
import { IFieldProps } from '../types'
import { STYLE } from '../../style'

interface ITextAreaProps extends IFieldProps {}

const StyledTextArea = styled.div`
  ${STYLE.FIELD_STYLE}
`

const TextArea: React.FunctionComponent<ITextAreaProps> = ({
  placeholder,
  labelText,
  required,
  disabled,
  onChange,
  value,
}) => (
  <StyledTextArea>
    {labelText && <LabelText required={required}>{labelText}</LabelText>}
    <Input.TextArea
      disabled={disabled}
      placeholder={placeholder}
      onChange={(ev) => onChange(ev, ev.target.value)}
      value={value}
      rows={5}
    />
  </StyledTextArea>
)

export default TextArea
