export enum PrescriptionSendTo {
  CACLINICS = 'CACLINICS',
  MY_PHARMACY = 'MY_PHARMACY',
  MY_ADDRESS = 'MY_ADDRESS',
}

export enum StateValues {
  VIC = 'VIC',
  NSW = 'NSW',
  ACT = 'ACT',
  TAS = 'TAS',
  QLD = 'QLD',
  SA = 'SA',
  WA = 'WA',
  NT = 'NT',
}

export enum ProjectId {
  CA_CLINIC = 'caclinic',
  PRESCRIPTION_VAPE = 'prescriptionvape',
}

export interface IResponse {
  data: object
}

export interface ICreatePaymentCard {
  id: string
  cardEntryMode: 'E_COMMERCE'
  dateCreated: number
  expMonth: number
  expYear: number
  indicator: 'DEBIT_CARD'
  indicatorSource: 'CARD_BIN'
  last4: string
  name: string
  type: 'MASTER_CARD'
  secure3DData: {
    acsUrl: string
    id: string
    isEnrolled: boolean
    md: string
    paReq: string
    termUrl: string
  }
}

export interface ICreatePaymentTokenResponse extends IResponse {
  data: {
    id: string
    currency: string
    amount: string
    description: string
    card: ICreatePaymentCard
  }
}

export interface ICreateSecure3dIframeResponse {
  token: string
}

export interface IAction {
  type: string
  payload: object
}

export interface FieldValidationRule {
  validate: (value: string) => boolean
  message: string
}

export interface IPrescriptionFormFields {
  existingCustomer?: string
  firstName: string
  lastName: string
  email: string
  mobile: string
  gender: string
  medicareCardNo: string
  medicareCardIRN: string
  medicareCardValidTo: string
  knowProductForSubscription: string
  productForPrescription: string
  sendPrescriptionTo: PrescriptionSendTo
  comments?: string
  creditCardNo: string
  creditCardName: string
  creditCardExpiryDate: string
  creditCardCVC: string
  lookForCheaperProduct: string
  pharmacyEmail?: string
  pharmacyName?: string
  pharmacyStreetAddress?: string
  pharmacySuburb?: string
  pharmacyPostcode?: string
  pharmacyState?: StateValues
  clientStreetAddress?: string
  clientSuburb?: string
  clientPostcode?: string
  clientState?: StateValues
}

export const States: Array<{ value: StateValues; text: string }> = [
  { value: null, text: '-- Select --' },
  { value: StateValues.ACT, text: 'ACT' },
  { value: StateValues.NSW, text: 'NSW' },
  { value: StateValues.NT, text: 'NT' },
  { value: StateValues.QLD, text: 'QLD' },
  { value: StateValues.SA, text: 'SA' },
  { value: StateValues.TAS, text: 'TAS' },
  { value: StateValues.VIC, text: 'VIC' },
  { value: StateValues.WA, text: 'WA' },
]
