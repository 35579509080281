import * as React from 'react'
import styled from 'styled-components'
import LabelText from '../../components/Text/LabelText'
import { STYLE } from '../../style'
import { IPrescriptionFormFields, PrescriptionSendTo } from '../../types'

interface IPaymentAmountInfoProps {
  fields: IPrescriptionFormFields
}

const StyledPaymentAmountInfo = styled.div`
  margin-bottom: ${STYLE.GUTTER};
`

const ValueText = styled.span`
  font-size: ${STYLE.FONT_SIZE_LG};
`

const Text = styled.span``

const PriceText = styled(ValueText)`
  margin-right: calc(${STYLE.GUTTER} / 2);
  font-size: ${STYLE.FONT_SIZE_XL};
`

const PaymentAmountInfo: React.FunctionComponent<IPaymentAmountInfoProps> = ({
  fields,
}) => {
  const prescriptionFee: number = 19
  let shippingFee: number = 0
  if (fields.sendPrescriptionTo !== PrescriptionSendTo.CACLINICS) {
    shippingFee = 27.5
  }
  const totalFee: number = prescriptionFee + shippingFee
  return (
    <StyledPaymentAmountInfo>
      <LabelText>Payment Amount</LabelText>
      <PriceText>${totalFee.toFixed(2)}</PriceText>
      <Text>inc. GST</Text>
    </StyledPaymentAmountInfo>
  )
}

export default PaymentAmountInfo
