import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { Radio, Space } from 'antd'
import HintText from '../Text/HintText'
import { IRadioGroupItem, IRadioGroupProps, RadioGroupDirection } from './types'
import { FieldHintTextPlacement } from '../types'
import { STYLE } from '../../style'
import FieldRules from '../Field/FieldRules'
import { FieldValidationRule } from '../../types'
import { FormHelper } from '../../helpers/FormHelper'
import FieldHeader from '../Field/FieldHeader'

const StyledRadioGroup = styled.div`
  ${STYLE.FIELD_STYLE}
`

const RadioItemContent = styled.div`
  margin-left: 2rem;
`

const RadioItemContainer = styled.div`
  div,
  select,
  input {
    font-size: ${STYLE.FONT_SIZE_SM};
  }
`

const RadioGroup: React.FunctionComponent<IRadioGroupProps> = ({
  items = [],
  labelText,
  disabled,
  hintText,
  hintTextPlacement = FieldHintTextPlacement.BOTTOM,
  direction = RadioGroupDirection.HORIZONTAL,
  rules = [],
  value,
  onChange,
}) => {
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const invalidRule: FieldValidationRule = FormHelper.validate(
    rules.filter((a) => a),
    value,
  )
  return (
    <StyledRadioGroup>
      <FieldHeader
        isDirty={isDirty}
        required={rules.includes(FieldRules.required)}
        validationMessage={invalidRule?.message}
        labelText={labelText}
        hintText={hintTextPlacement === FieldHintTextPlacement.TOP && hintText}
      />
      <Radio.Group
        value={value}
        disabled={disabled}
        onChange={(ev) => {
          setIsDirty(true)
          onChange(ev, ev.target.value)
        }}
      >
        <Space direction={direction}>
          {items.map((item: IRadioGroupItem) => {
            const itemValue: string = item.value
            return (
              <RadioItemContainer key={itemValue}>
                <Radio value={itemValue}>{item.text}</Radio>
                {itemValue === value && (
                  <RadioItemContent>{item.children}</RadioItemContent>
                )}
              </RadioItemContainer>
            )
          })}
        </Space>
      </Radio.Group>
      {hintText && hintTextPlacement === FieldHintTextPlacement.BOTTOM && (
        <HintText>{hintText}</HintText>
      )}
    </StyledRadioGroup>
  )
}

export default RadioGroup
