export const STYLE: { [key: string]: string } = {
  FIELD_MARGIN: '0 0 1.2rem 0',
  GUTTER: '1rem',

  FONT_SIZE_XS: '0.725rem',
  FONT_SIZE_SM: '0.825rem',
  FONT_SIZE_MD: '1rem',
  FONT_SIZE_LG: '1.25rem',
  FONT_SIZE_XL: '2rem',

  FONT_WEIGHT_LIGHT: '100',
  FONT_WEIGHT_NORMAL: '300',
  FONT_WEIGHT_SEMI_BOLD: '400',
  FONT_WEIGHT_BOLD: '600',
}

STYLE.FIELD_STYLE = `
  margin: ${STYLE.FIELD_MARGIN}
`

export const COLOR: { [key: string]: string } = {
  GRAY: '#999',
  BLACK: '#000',
  WHITE: '#FFF',
  PRIMARY: '#007B93',
  LIGHT_GRAY: 'rgba(217, 217, 217)',
}

COLOR.TEXT_COLOR = COLOR.BLACK
COLOR.HINT_TEXT_COLOR = COLOR.GRAY
COLOR.FIELD_BORDER_COLOR = COLOR.LIGHT_GRAY
