import * as React from 'react'
import { Button as AntButton } from 'antd'
import styled from 'styled-components'
import { COLOR, STYLE } from '../../style'

export enum ButtonType {
  PRIMARY = 'primary',
  DASHED = 'dashed',
  TEXT = 'text',
  LINK = 'link',
}

export enum ButtonHtmlType {
  SUBMIT = 'submit',
  RESET = 'reset',
  BUTTON = 'button',
}

interface IButtonProps {
  children: React.ReactNode
  type?: ButtonType
  loading?: boolean
  block?: boolean
  htmlType?: string
  className?: string
}

const StyledButton = styled(AntButton)`
  background-color: ${COLOR.PRIMARY};
  color: ${COLOR.WHITE};
  border: 0;
  font-size: ${STYLE.FONT_SIZE_LG};
  height: auto;
  &:hover {
    background-color: ${COLOR.PRIMARY};
    opacity: 0.8;
  }
  &:active,
  &:focus {
    background-color: ${COLOR.PRIMARY};
  }
`

const Button: React.FunctionComponent<IButtonProps> = ({
  children,
  className,
  htmlType = ButtonHtmlType.BUTTON,
  type = ButtonType.PRIMARY,
  loading = false,
  block = true,
}) => (
  <StyledButton
    className={className}
    block={block}
    htmlType={htmlType}
    loading={loading}
    type={type}
  >
    {children}
  </StyledButton>
)

export default Button
