import * as React from 'react'
import { Layout as AntLayout } from 'antd'
import styled from 'styled-components'
import 'antd/dist/antd.css'
import './index.css'
import { COLOR } from '../../style'
import { SCREEN_SIZE } from '../../breakpoints'

interface ILayoutProps {
  children: React.ReactNode
  className?: string
}

const StyledLayout = styled(AntLayout)``

const Content = styled(AntLayout.Content)`
  padding: 0.5rem 0;
  && {
    min-height: 100vh;
  }
  ${SCREEN_SIZE.TABLET} {
    padding: 3rem 0;
  }
`

const MainContent = styled.div`
  background-color: ${COLOR.WHITE};
  width: 96%;
  min-width: 18.75rem;
  max-width: 40rem;
  margin: 0 auto;
  padding: 2rem;
  ${SCREEN_SIZE.TABLET} {
    padding: 3rem;
    width: 80%;
  }
`

const Layout: React.FunctionComponent<ILayoutProps> = ({
  children,
  className,
}) => (
  <StyledLayout>
    <Content className={className}>
      <MainContent>{children}</MainContent>
    </Content>
  </StyledLayout>
)

export default Layout
